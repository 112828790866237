<template>
    <div class="header-notifications-dropdown">

        <!-- User Status -->
        <div class="user-status">
            <!-- User Name / Avatar -->
            <div class="user-details">
                <UserProfileImage :profilePic="user.profilePic" :logo="user.logo" />

                <div v-if="user && user.name" class="user-name">
                    {{ user.name }}
                    <span v-if="user.email">{{ user.email }}</span>
                </div>
            </div>
        </div>

        <ul class="user-menu-small-nav">
            <li v-for="userMenuLink in userMenuLinkList" :key="userMenuLink.name">
                <div v-if="userMenuLink.name === 'Logout'" @click="logout" class="log-out">
                    <component v-if="userMenuLink.icon" class="icon" :size="16" :is="userMenuLink.icon"></component>
                    {{ userMenuLink.name }}
                </div>

                <NuxtLink v-else :to="userMenuLink.to">
                    <component v-if="userMenuLink.icon" class="icon" :size="16" :is="userMenuLink.icon"></component>
                    {{ userMenuLink.name }}
                </NuxtLink>
            </li>
        </ul>

    </div>
    <!-- User Menu / End -->
</template>

<script>
import ViewDashboardOutline from 'icons/ViewDashboardOutline.vue';
import FileEditorOutline from "icons/FileEditOutline.vue";
import Power from 'icons/Power.vue';
import IconAccount from 'vue-material-design-icons/Account.vue'
import { mapState } from 'vuex'
import UserProfileImage from './UserProfileImage..vue';
export default {
    components: {
        ViewDashboardOutline,
        FileEditorOutline,
        Power,
        IconAccount,
        UserProfileImage
    },
    computed: {
        ...mapState('auth', ['user'])
    },
    methods: {
        // generateLogoutLink() {
        //     return `/logout?redirect=${encodeURIComponent(this.$route.fullPath)}`;
        // },
        async logout() {
            var self = this;
            $nuxt.$root.$loading.start();
            localStorage.removeItem("isAdmin"); // Remove admin flag if was defined

            await this.$auth.logout()
                .then(() => {
                    setTimeout(x => {
                        if (self.$route.path.startsWith('/dashboard'))
                            self.$router.push('/');

                        $nuxt.$root.$loading.finish();
                    }, 1000);
                    //    this.$router.replace('/')
                    // self.$router.push({ path: self.$route.query.redirect });

                })
        },
    },
    data() {
        return {
            userMenuLinkList: [
                {
                    name: "Dashboard",
                    to: "/dashboard/",
                    icon: "ViewDashboardOutline"
                },
                {
                    name: "Edit Ads",
                    to: "/dashboard/manage-ads",
                    icon: "FileEditorOutline"
                },
                {
                    name: "Profile",
                    to: "/dashboard/me",
                    icon: "IconAccount"
                },
                {
                    name: "Logout",
                    to: "/logout",
                    icon: "Power"
                },
            ]
        }
    },

}
</script>

<style lang="scss" scoped>
.icon {
    position: relative;
    bottom: -2.5px;
}

.log-out {
    cursor: pointer;
}
</style>